import React, {Fragment} from 'react';

const Team = props => {
    return (
        <Fragment>
            <h2 className="oriya-uppercase-medium">Team</h2>
            <div className="about-team">
                <div>
                    <div className="about-team-photos">
                        <img src="../../../assets/founder.jpeg" alt="Photo of Patricia Goh - Hello Accessibility's founder" />
                    </div>
                    <div className="about-team-name">
                        <h2 className="avenir-capitalise-small">Patricia Goh</h2>
                        <h3 className="avenir-lowercase-tiny"><i><b>(she/her)</b></i></h3>
                        <h3 className="avenir-lowercase-tiny" aria-label="Emoji flag of Canada" title="Patricia is based in Canada.">🇨🇦</h3>
                    </div>
                    <p className="avenir-uppercase-tiny">Founder</p>
                    <br />
                    <p>
                        With almost a decade in software engineering, I specialize in accessible front-end development and technical leadership. As the founder of Hello Accessible, I help organizations embed accessibility into their development lifecycle, bridging the gap between engineering, UX, and compliance. My mission is to establish accessibility as a core engineering discipline, not an afterthought, ensuring lasting impact through inclusive and meaningful digital experiences.
                    </p>
                </div>
                <div>
                    <div className="about-team-photos">
                        <img src="../../../assets/laura.jpg" alt="Photo of Laura Lee - Hello Accessibility's Sales Advisor" />
                    </div>
                    <div className="about-team-name">
                        <h2 className="avenir-capitalise-small">Laura Lee</h2>
                        <h3 className="avenir-lowercase-tiny"><i><b>(she/her)</b></i></h3>
                        <h3 className="avenir-lowercase-tiny" aria-label="Emoji flag of Netherlands" title="Laura is based in Netherlands.">🇳🇱</h3>
                    </div>
                    <p className="avenir-uppercase-tiny">Business Development Advisor</p>
                    <br />
                    <p>
                        As an advisor for Hello Accessible, I provide strategic guidance on sales, growth, and market positioning. With a background in business strategy, design, and sustainability, I offer insights that support informed decision-making, innovation, and long-term business development. My mission is to ensure accessibility and sustainability go hand in hand, shaping sales strategies that drive meaningful impact and a future where technology is both inclusive and regenerative.
                    </p>
                </div>
                <div>
                    <div className="about-team-photos">
                        <img src="../../../assets/head-of-purrfessional-wellness.jpeg" alt="Photo of Hello Accessibility's emotional support cat" />
                    </div>
                    <div className="about-team-name">
                        <h2 className="avenir-capitalise-small">Kafka</h2>
                        <h3 className="avenir-lowercase-tiny"><i><b>(he/him)</b></i></h3>
                        <h3 className="avenir-lowercase-tiny" aria-label="Emoji flag of Estonia" title="Kafka is based in Estonia.">🇪🇪</h3>
                    </div>
                    <p className="avenir-uppercase-tiny">Purr-fessional Wellness Officer</p>
                    <br />
                    <p>
                        As Hello Accessible's resident cuddle connoisseur, I bring a natural curiosity and keen eye for detail. Through the years hunting down actual bugs, I have mastered the art of spotting "invisible" details, just like our accessibility experts! I ensure my team takes stretch breaks and stay paw-sitive. I excel in testing my team's work by walking across their keyboards. My presence reminds my team that small gestures can make great impact, just like our accessibility efforts.
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default Team;