import React, {Fragment} from 'react';

const GeneralAbout = props => {
    return (
        <Fragment>
            <h1 className="oriya-uppercase-medium">About Us</h1>
            <div className="about-header">
                <div className="header-column-left">
                    <h2 className="avenir-capitalise-small">Our Story</h2>
                    <p>
                        Can you believe it? It’s been less than two decades since the first iPhone launched — barely a generation ago.
                        <br /><br />
                        As the world marveled at each wave of innovation, we couldn’t help but notice a significant group often left behind. These are people who contribute, create, and engage in society — just not one that considers their needs by default. These are the people around us. These are our friends, family, colleagues... our community.
                        <br /><br />
                        We want to change that and that's how Hello Accessible was born.
                    </p>
                </div>
                <div className="header-column-right">
                    <h2 className="avenir-capitalise-small">Our Mission</h2>
                    <p>
                        We are on a mission to bridge the gap between technology, business, and inclusivity. We believe your audiences — regardless of their abilities — deserve seamless access to your services and offerings.
                        <br /><br />
                        At Hello Accessible, our expertise sets us apart. We deliver practical, actionable solutions that make accessibility improvements realistic, sustainable, and impactful. Together, we will take the technical burden of digital accessibility off your shoulders, so you can focus on your business.
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default GeneralAbout;