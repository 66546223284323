import React, {Fragment} from 'react';
import { Helmet } from "react-helmet-async";
import GeneralAbout from './about/GeneralAbout';
import Team from './about/Team';
import Careers from './about/Careers';
 
const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>About Hello Accessible - Digital Accessibility Consulting Firm | Vancouver</title>
      </Helmet>
      <section className="about-page section-padding"  role="contentinfo" id="content">
        <GeneralAbout />
        <div className="membership-section">
          <p class="avenir-uppercase-tiny">Hello Accessible is a proud member of the following entities</p>
          <div className="about-badge-container spotlight-section">
              <a href="https://clutch.co/profile/hello-accessible" target="_blank" alt="Open Clutch profile for Hello Accessible in a new tab">
                  <img src="../../../assets/badge-clutch-bw.png" alt="Badge from Clutch - Women Owned Business" title="Hello Accessible is a Clutch Certified Women Owned Business" className="img-badge-clutch"/>
              </a>
              <a href="https://www.credly.com/badges/52f61726-a386-46b0-bbc4-1b75257b8076/public_url" target="_blank" alt="Open verified badge page on Credly in a new tab">
                  <img src="../../../assets/badge-iaap-bw.png" alt="IAAP Membership Badge for Hello Accessible" title="Hello Accessible is a member of IAAP" className="img-badge-iaap" />
              </a>
              <a href="https://accessibleemployers.ca/member/hello-accessible/" target="_blank" alt="Open Hello Accessible's page on Accessible Employers in a new tab">
                  <img src="../../../assets/badge-cae-bw.png" alt="Community of Accessible Employers Membership Badge for Hello Accessible" title="Hello Accessible is a member of Community of Accessible Employers" className="img-badge-cae"/>
              </a>
          </div>
        </div>
      </section>
      <hr />
      <section className="about-page section-padding"  role="contentinfo" id="content">
        <Team />
      </section>
      <hr />
      <section className="about-page section-padding"  role="contentinfo" id="content">
        <Careers />
      </section>
    </Fragment>
  );
}

export default About;
