import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { 
  IconAudit, IconTrainings, IconVpat, IconRemediation, IconPlan, IconAaaS, 
} from '../../components/icons/Icons';

const HomeServices = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => navigate('/services');
  
    return (
      <section className="home-services-section section-padding" id="services" role="contentinfo">
        <div className="section-heading">
          <p className="avenir-uppercase-tiny">Your one-stop shop for inclusive experiences</p>
          <h2 className="oriya-uppercase-medium">Our Digital Accessibility Solutions</h2>
          <p>Our strength lies in handling the complex, technical aspects of digital accessibility, so you don’t have to.</p>
        </div>
        <div className="section-services">
          <NavLink to="/services/audit">
            <div>
              <IconAudit /> 
              <h3 className="avenir-uppercase-tiny">Audit</h3>
            </div>
            <p className="">A thorough manual review with actionable insights & fix suggestions.</p>
          </NavLink>
          <NavLink to="/services/remediation">
            <div>
              <IconRemediation /> 
              <h3 className="avenir-uppercase-tiny">Remediation</h3>
            </div>
            <p className="">Comprehensive fixes to ensure accessibility compliance.</p>
          </NavLink>
          <NavLink to="/services/trainings">
            <div>
              <IconTrainings /> 
              <h3 className="avenir-uppercase-tiny">Trainings</h3>
            </div>
            <p className="">Tailored sessions to equip your team with necessary skills.</p>
          </NavLink>
          <NavLink to="/services/accessibility-as-a-service">
            <div>
              <IconAaaS /> 
              <h3 className="avenir-uppercase-tiny">Accessibility as a Service</h3>
            </div>
            <p className="">Year-round support to handle everything — your all-in-one accessibility solution.</p>
          </NavLink>
          <NavLink to="/services/roadmap-planning">
            <div>
              <IconPlan /> 
              <h3 className="avenir-uppercase-tiny">Roadmap Planning</h3>
            </div>
            <p className="">Your step-by-step plan to achieve full digital accessibility compliance.</p>
          </NavLink>
          <NavLink to="/services/vpat">
            <div>
              <IconVpat /> 
              <h3 className="avenir-uppercase-tiny">VPAT</h3>
            </div>
            <p className="">Professional VPATs for clear and compliant reporting.</p>
          </NavLink>
        </div>
        <button onClick={handleButtonClick} className="button">
          View plans & pricing
        </button>
      </section>
    );
}

export default HomeServices;
