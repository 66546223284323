import React from 'react';
import {IconUSA, IconWorld, IconCanada, IconEurope} from '../../components/icons/Icons';

const HomeLaws = () => {
    return (
      <section className="law-section" id="laws-marquee" role="contentinfo">
          <div className="section-heading">
            <p className="avenir-uppercase-tiny">For your peace of mind</p>
            <h2 className="oriya-uppercase-medium">Stay ahead of accessibility laws</h2>
            <p>
              In 2023, 8,000+ lawsuits were filed in federal court under Title III of the ADA. Navigating the complex landscape of accessibility laws and regulations can be daunting, but it doesn't have to be. At Hello Accessible, we stay ahead of the curve by constantly monitoring and interpreting the latest legal requirements, ensuring your business remains compliant and inclusive. Our dedicated team of experts provides tailored guidance and practical solutions, giving you the peace of mind to focus on what you do best. With our support, you can confidently meet and exceed accessibility standards.
            </p>
          </div>
          <div className="law-button-container">
            <div>
              <IconWorld /> WCAG
            </div>
            <div>
              <IconUSA /> ADA
            </div>
            <div>
              <IconUSA /> Sect. 508
            </div>
            <div>
              <IconCanada /> ACA
            </div>
            <div>
              <IconEurope /> EAA
            </div>
            <div>
              <IconEurope /> EN 301 549
            </div>
            <div>
              <IconCanada /> AODA
            </div>
            <div>
              & More
            </div>
          </div>
      </section>
    );
  }

export default HomeLaws;