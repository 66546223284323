import React, {Fragment} from 'react';

const Careers = props => {
    return (
        <Fragment>
            <h2 className="oriya-uppercase-medium">Join Our Team</h2>
            <br />
            <p>
                We are always looking for talented and passionate accessibility professionals to collaborate with. We're currently actively interested to fill these roles:
                <ul>
                    <li>• Sales Specialist</li>
                    <li>• Content Marketing Specialist</li>
                    <li>• Accessibility Tester</li>
                </ul>
                <br />
                <b>Not seeing the perfect fit?</b>
                <br />
                If you’re passionate about digital accessibility but don’t see a role that matches your expertise, we’d still love to hear from you! We’re always open to connecting with talented individuals who share our mission.
                <br /><br />
                <b>How to apply?</b>
                <br />
                Send your resume and a brief introduction about your expertise to <a href="mailto:support@helloaccessible.com"><b>support@helloaccessible.com</b></a>.
                <br /><br />
                Let's make the digital world more inclusive - together.
            </p>
        </Fragment>
    )
}

export default Careers;